import React from "react";

import { SXForbiddenContainer, SXImage } from "./Forbidden.styled";

const Forbidden = (props) => {
  return (
    <SXForbiddenContainer>
      <SXImage>
        <img
          src="/assets/images/forbidden.png"
          alt=""
          width={"100%"}
          height={"100%"}
        />
      </SXImage>
      <div>
        <p>{props.text}</p>
      </div>
    </SXForbiddenContainer>
  );
};

export default Forbidden;
