import React from "react";

// Components
import { Text } from "@inspigoid/inspids-react";

// Styled
import { SXContainer, SXNoContent, SXImage } from "./NotFound.styled";

const NotFound = () => {
  return (
    <SXNoContent>
      <SXContainer>
        <SXImage
          src="/assets/images/notfound.png"
          width={400}
          height={400}
          alt={"notfound"}
        />
        <Text type="p" textAlign={"center"} fontSize={"16px"}>
          Maaf, halaman yang kamu cari tidak ditemukan.
        </Text>
      </SXContainer>
    </SXNoContent>
  );
};

export default NotFound;
