import { forumCategoriesActionType } from "../../types/forumCategorisType";

const initialState = {
  loading: true,
  status: null,
  list: [],
  detail: {},
};

const ForumCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case forumCategoriesActionType.GET_FORUM_CATEGORIES:
      return {
        ...state,
        loading: action.payload.loading,
        status: action.payload.status,
        list: action.payload.list,
      };
    case forumCategoriesActionType.GET_FORUM_CATEGORY_BY_ID:
      return {
        ...state,
        loading: action.payload.loading,
        status: action.payload.status,
        detail: action.payload.detail,
      };
    case forumCategoriesActionType.CLEAR_FORUM_CATEGORIES:
      return {
        ...state,
        loading: action.payload.loading,
        status: action.payload.status,
      };
    default:
      return state;
  }
};

export default ForumCategoryReducer;
