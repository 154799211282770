import { loginActionType } from "../../types/loginType";

const initialState = {
  status: null,
  data: {},
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginActionType.GET_ACCOUNT:
      return action.payload;

    default:
      return state;
  }
};

export default loginReducer;
