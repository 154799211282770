import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { InspiColors } from "@inspigoid/inspids-react";

export const SXDashboardLayout = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`;

export const SXMain = styled.main`
  width: 100%;
  flex: 1 0 auto;
  height: auto;
  margin-top: 4rem;
  margin-bottom: ${({ extended }) => (extended ? "8.9rem" : "0")};
  transition: all 0.4s;

  @media (max-width: ${breakpoints.mobileL}) {
    margin-top: 2rem;
    margin-bottom: ${({ extended }) => (extended ? "6.5rem" : "0")};
  }
`;

export const SXMainContainer = styled.div`
  margin-bottom: 5.7rem;
  height: auto;
  min-height: 100vh;
  padding: 4rem;
  background-color: white;
  border: 1px solid ${InspiColors.JetBlack50};
  border-radius: 20px;

  @media (max-width: ${breakpoints.mobileL}) {
    padding: unset;
    border-radius: unset;
    border: unset;
  }
`;

export const SXSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20rem;
`;
