import { postType } from "../../types/postType";

const initialState = {
  loading: true,
  status: null,
  data: [],
};

const PostReducer = (state = initialState, action) => {
  switch (action.type) {
    case postType.GET_POST:
      return action.payload;
    default:
      return state;
  }
};

export default PostReducer;
