import { env } from "../config";

export const ORIGIN = env.ORIGIN;
export const BASEURL = env.BASEURL;
export const MEMBER = env.INSPIGO_MEMBER_URL;

export const SERVICE = {
  ACCOUNT: env.SERVICE_ACCOUNT_URL,
  API: env.REACT_APP_INSPIGO_DISCUSSION_FORUM_API,
};

export const GRANT_TYPE = {
  TOKEN: "token",
  PASSWORD: "password",
};

export const LOCAL_STORAGE = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
};
