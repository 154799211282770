import styled from "styled-components";

export const SXForbiddenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 60rem;
  margin: 8rem auto;
`;

export const SXImage = styled.div`
  display: flex;
  width: 20rem;
  height: 20rem;
  margin-bottom: 2rem;

  img {
    width: inherit;
    height: inherit;
  }
`;
