// Libraries
import React, { useEffect, useState } from "react";
import { Box, Text, InspiColors, Button } from "@inspigoid/inspids-react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Styled
import {
  SXDetailForumContainer,
  SXHeader,
  SXHeaderInformation,
  SXDivider,
  SXHeaderAction,
  SXList,
  SXLogo,
  SXRuleContainer,
  SXAssetRuleContainer,
  SXHeaderResponsive,
  SXSpinner,
  SXDotButton,
  SXRuleImage,
} from "./DetailForumPage.styled";

// Components
import { MoonLoader } from "react-spinners";
import EmptyContent from "components/EmptyContent/EmptyContent";
import BottomSheetComponent from "components/BottomSheet/BottomSheetComponent";
import DetailForumContentResponsive from "./DetailForumContentResponsive/DetailForumContentResponsive";

// Request
import Request from "redux/request";

// loader
import { DetailForumPageLoader } from "utils/loader";

// Modal
import CreatePostModal from "components/Modal/CreatePostModal/CreatePostModal";
import RulesModal from "components/Modal/RulesModal/RulesModal";

// PostContentList
import PostContentList from "./PostContentList/PostContentList";

// actions
import { fetchForumCategoryById } from "redux/actions/content/_forum-categories";

// api
import { content } from "utils/apiroutelist";

// default tag image
const defaultTagImage = "/assets/images/default-tags-image.svg";

const DetailForumPage = () => {
  const { id, path } = useParams();
  const { forumCategories } = useSelector(({ rootReducer }) => rootReducer);
  const [posts, setPosts] = useState({
    status: null,
    list: [],
  });
  const [loading, setLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalRules, setIsOpenModalRules] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [forumId, setForumId] = useState("");
  const [refreshPost, setRefreshPost] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    dispatch(fetchForumCategoryById(id));
    setLoading(true);
    getPosts();
  }, [id, refreshPost]);

  const getForumByPath = async (path) => {
    const request = new Request(null, null, true);
    const response = await request.get(content.forumByPath(path));
    const { id: forumId } = response?.data?.data;
    setForumId(forumId);
    return {
      forumId,
    };
  };

  const getPosts = async () => {
    const { forumId } = await getForumByPath(path);
    const tagId = id;
    const request = new Request(null, null, true);
    const responsePosts = await request.get(
      content.forumCategoryPosts(forumId, tagId)
    );
    const status = responsePosts?.status;
    const posts = responsePosts?.data?.data;
    setPosts({
      status: status,
      list: posts,
    });
    setLoading(false);
  };

  const {
    loading: loadingForumCategories,
    detail: data,
  } = forumCategories;

  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleOnCloseModalRule = () => {
    setOpenBottomSheet(false);
    setIsOpenModalRules(!isOpenModalRules);
  };

  const onDismissBottomSheet = () => {
    setOpenBottomSheet(false);
  };

  return (
    <>
      <BottomSheetComponent
        open={openBottomSheet}
        onDismiss={onDismissBottomSheet}
      >
        <DetailForumContentResponsive
          image={data?.asset?.content || defaultTagImage}
          title={data?.name}
          description={data?.description}
          handleOnCloseModalRule={handleOnCloseModalRule}
        />
      </BottomSheetComponent>
      <RulesModal isOpen={isOpenModalRules} onClose={handleOnCloseModalRule} />
      <CreatePostModal
        isOpen={isOpenModal}
        onClose={handleToggleModal}
        categoryId={id}
        forumId={forumId}
        setRefreshPost={setRefreshPost}
      />
      <SXDetailForumContainer>
        {loadingForumCategories ? (
          <DetailForumPageLoader height={150} width={"100%"} />
        ) : (
          <>
            <SXHeader>
              <SXLogo>
                <img
                  src={data?.asset?.content || defaultTagImage}
                  alt={data?.name}
                />
              </SXLogo>

              <SXHeaderResponsive>
                <Box>
                  <Text
                    type="h5"
                    fontSize={{
                      tabletM: 24,
                      mobileS: 20,
                    }}
                  >
                    {data?.name}
                  </Text>
                </Box>
                <SXDotButton onClick={() => setOpenBottomSheet(true)}>
                  <img src="/assets/icons/dot-button.svg" alt="dot-button" />
                </SXDotButton>
              </SXHeaderResponsive>

              <SXHeaderInformation>
                <Box mb={10}>
                  <Text
                    type="h5"
                    fontSize={{
                      tabletM: 24,
                      mobileS: 20,
                    }}
                  >
                    {data?.name}
                  </Text>
                </Box>
                <Box>
                  <Text type="p">{data?.description}</Text>
                </Box>

                <SXRuleContainer>
                  <SXAssetRuleContainer>
                    <SXRuleImage>
                      <img src="/assets/images/Asset.png" alt="" />
                    </SXRuleImage>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        type="p"
                        fontSize={"13px"}
                        fontWeight={700}
                        color={InspiColors.JetBlack500}
                        textAlign="center"
                      >
                        Please read these rules before posting
                      </Text>
                    </Box>
                  </SXAssetRuleContainer>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <Text
                      type="p"
                      textAlign={"center"}
                      fontWeight={700}
                      fontSize={13}
                      color={InspiColors.InspirationBlue500}
                      onClick={() => handleOnCloseModalRule()}
                    >
                      Check Rule
                    </Text>
                  </Box>
                </SXRuleContainer>
              </SXHeaderInformation>

              <SXHeaderAction>
                <Button onClick={() => handleToggleModal()}>
                  Kirim Pertanyaan
                </Button>
              </SXHeaderAction>
            </SXHeader>
          </>
        )}

        <SXDivider />

        <SXList>
          {loading ? (
            <SXSpinner>
              <MoonLoader size={40} color={InspiColors.JetBlack400} />
            </SXSpinner>
          ) : posts.status !== 200 || posts.list.length <= 0 ? (
            <EmptyContent text={"Ayo mulai diskusi dan berbagi inspirasi"} />
          ) : (
            <PostContentList posts={posts.list} />
          )}
        </SXList>
      </SXDetailForumContainer>
    </>
  );
};

export default DetailForumPage;
