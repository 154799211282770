import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MoonLoader } from "react-spinners";

import { InspiDS, InspiColors, Text } from "@inspigoid/inspids-react";
import DashboardLayout from "./hocs/layouts/DashboardLayout/DashboardLayout";

// Pages
import HomeForumView from "./pages/HomeForumView/HomeForumView";
import DetailForumPage from "./pages/DetailForumPage/DetailForumPage";
import DetailPostPage from "./pages/DetailPostPage/DetailPostPage";

// theme
import { theme } from "./utils/theme";

// actions
import { fetchAccount } from "./redux/actions/account/_login";
import { fetchForum } from "./redux/actions/content/_forum";
import Forbidden from "./components/Forbidden/Forbidden";


//global style
import "./App.scss";

// styled components
const SXSpinner = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const App = () => {
  const { user } = useSelector(({ rootReducer }) => rootReducer);
  const [forumPath, setForumPath] = useState(null);
  const [loadingForum, setLoadingForum] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useMemo(() => history.location.pathname, []);


  useEffect(() => {
    setLoadingForum(true);
    getAccount();
    history.replace(url);
  }, [url]);

  useEffect(() => {
    if (!user?.status) return;
    getForum();
  }, [user?.status]);

  const getAccount = async () => {
    await dispatch(fetchAccount());
    return;
  };

  const getForum = async () => {
    const response = await dispatch(fetchForum());
    const { path } = response?.data?.data;

    if (path) {
      setForumPath(path);
      setLoadingForum(false);
      return;
    }
  };

  if (loadingForum) {
    return (
      <ThemeProvider theme={theme}>
        <InspiDS>
          <SXSpinner>
            <MoonLoader size={40} color={InspiColors.JetBlack400} />
            <Text
              type="p"
              fontSize="16px"
              mt={"20px"}
              color={InspiColors.JetBlack500}
            >
              Mempersiapkan Forum
            </Text>
          </SXSpinner>
        </InspiDS>
      </ThemeProvider>
    );
  }

  if (!forumPath) {
    return (
      <ThemeProvider theme={theme}>
        <InspiDS>
          <DashboardLayout>
            <Forbidden text="Kamu tidak memiliki akses ke halaman ini" />;
          </DashboardLayout>
        </InspiDS>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <InspiDS>
        <DashboardLayout>
          <Switch>
            {/* home */}
            <Route exact path="/">
              {forumPath && <Redirect to={`/forum/${forumPath}`} />}
            </Route>

            <Route exact path="/forum/:path" component={HomeForumView} />

            {/* forum detail */}
            <Route
              exact
              path="/forum/:path/detail/:id"
              component={DetailForumPage}
            />

            {/* post detail */}
            <Route
              exact
              path="/forum/:path/detail/:id/post/:postId"
              component={DetailPostPage}
            />
          </Switch>
        </DashboardLayout>
      </InspiDS>
    </ThemeProvider>
  );
};
export default React.memo(App);
