import styled from "styled-components";

import { InspiColors } from "@inspigoid/inspids-react";

//breakpoints
import { breakpoints } from "utils/breakpoints";

export const SXDialogPanel = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 50rem;
  height: auto;
  background-color: white;
  border-radius: 20px;
  z-index: 999999;

  @media (max-width: ${breakpoints.mobileL}) {
    width: 100%;
    top: unset;
    bottom: 0;
    max-height: unset;
    overflow-y: unset;
    transform: translate(-50%, 0);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const SXHeader = styled.div`
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 50rem;
  aspect-ratio: 2/1;
  background-color: ${InspiColors.InspirationBlue500};
  z-index: 9;

  @media (max-width: ${breakpoints.mobileL}) {
    width: 100%;
  }
`;

export const SXHeaderDecoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
`;

export const SXHeaderLottie = styled.div`
  display: flex;
  position: absolute;
  top: -10rem;
  left: 50%;
  transform: translateX(-50%);
  width: 40rem;
  aspect-ratio: 1/1;

  @media (max-width: ${breakpoints.mobileL}) {
    width: 80vw;
    top: -20vw;
  }
`;

export const SXBody = styled.div`
  position: relative;
  padding: 3rem;
  z-index: 99;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  @media (max-width: ${breakpoints.mobileL}) {
    padding: 2rem;
    padding-bottom: 3.2rem;
  }
`;

export const SXDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px;
`;

export const SXDescriptionContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
