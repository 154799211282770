console.log(process.env.REACT_APP_ENV);

module.exports = {
  env: {
    ENVIRONMENT: process.env.REACT_APP_ENV,
    PORT: process.env.PORT,
    BASEURL: process.env.REACT_BASEURL,
    REACT_APP_INSPIGO_DISCUSSION_FORUM_API:process.env.REACT_APP_INSPIGO_DISCUSSION_FORUM_API,
    SERVICE_ACCOUNT_URL: process.env.REACT_APP_SERVICE_ACCOUNT_URL,
    INSPIGO_MEMBER_URL: process.env.REACT_APP_INSPIGO_MEMBER_URL,
    ORIGIN: process.env.REACT_ORIGIN,
  },
};
