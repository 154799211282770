import { LOCAL_STORAGE } from "utils/constant";

/**
 * Save token
 * @param {object} token
 */
export const saveToken = (token) => {
  localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, token.access);
};

/**
 * Remove token
 */
export const removeToken = () => {
  localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);

  // localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
};

/**
 * Get query param
 * @param {string} param
 * @returns
 */
export const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

/**
 *
 * @param name
 * @returns
 */
export const getFirstName = (name) => {
  const split = name?.split(" ");
  return split[0];
};
