import styled from "styled-components";

// breakpoints
import { breakpoints } from "utils/breakpoints";

export const SXDetailPostPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: ${breakpoints.mobileL}) {
    gap: 2rem;
  }
`;

export const SXReplyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const SXSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15rem;
`;
