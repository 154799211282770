import ContentLoader from "react-content-loader";

export const DetailForumPageLoader = ({ height, width }) => {
  return (
    <ContentLoader height={height} width={width}>
      <circle cx="30" cy="33" r="25" />
      <rect x="70" y="27" rx="4" ry="4" width="200" height="13" />
      <rect x="70" y="50" rx="4" ry="4" width="700" height="13" />
      <rect x="70" y="77" rx="4" ry="4" width="700" height="13" />
      <rect x="70" y="104" rx="4" ry="4" width="700" height="13" />
    </ContentLoader>
  );
};

export const DetailPostPageLoader = ({ height, width }) => {
  return (
    <ContentLoader height={height} width={width}>
      <circle cx="30" cy="40" r="25" />
      <rect x="70" y="20" rx="4" ry="4" width="200" height="13" />
      <rect x="70" y="45" rx="4" ry="4" width="200" height="13" />
      <rect x="10" y="80" rx="4" ry="4" width="700" height="13" />
      <rect x="10" y="110" rx="4" ry="4" width="700" height="13" />
      <rect x="10" y="150" rx="4" ry="4" width="80" height="13" />
      <rect x="100" y="150" rx="4" ry="4" width="80" height="13" />
    </ContentLoader>
  );
};
