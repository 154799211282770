export const breakpoints = {};
breakpoints.mobileS = "320px";
breakpoints.mobileM = "375px";
breakpoints.mobileL = "525px";
breakpoints.tabletS = "678px";
breakpoints.tabletM = "768px";
breakpoints.tabletL = "820px";
breakpoints.laptopM = "1024px";
breakpoints.laptopL = "1440px";
breakpoints.desktopM = "2560px";
