import React from "react";
import Lottie from "lottie-react";

// Packages
import { Box, Text } from "@inspigoid/inspids-react";

// Styled
import { SXEmptyContent, SXLottie } from "./EmptyContent.styled";

// Assets
import emptyAnim from "./Lottie/empty.json";

const EmptyContent = ({ text }) => {
  return (
    <SXEmptyContent>
      <SXLottie>
        <Lottie animationData={emptyAnim} loop={true}></Lottie>
      </SXLottie>

      <Box>
        <Text
          type="p"
          textAlign="center"
          fontSize={{
            tabletM: 16,
            mobileS: 13,
          }}
        >
          {text || "No data available"}
        </Text>
      </Box>
    </SXEmptyContent>
  );
};

export default EmptyContent;
