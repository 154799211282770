import React from "react";

// Packages
import { Avatar, Box, InspiColors, Text } from "@inspigoid/inspids-react";

// styled
import {
  SXReplyContent,
  SXReplyContentInformation,
  SXInformationHeader,
  SXHeaderProfile,
  SXTitle,
  SXDescription,
  SXBoxDate,
  SXAvatar,
  SXRightReply,
} from "./ReplyContent.styled";

// moment
import moment from "moment";

const defaultAvatarUrl =
  "https://cdn.inspigo.id/public/cover/default-profile/avatar5.png";

const ReplyContent = ({ reply }) => {
  const { author, comment, c_at } = reply;
  return (
    <SXReplyContent>
      <SXReplyContentInformation>
        <SXAvatar>
          <Avatar
            src={author?.cover ? author?.cover : defaultAvatarUrl}
            size="s"
            variant="circle"
          />
        </SXAvatar>

        <SXRightReply>
          <SXInformationHeader>
            <SXHeaderProfile>
              <Box>
                <SXTitle>
                  <Text
                    type="p"
                    fontWeight={700}
                    fontSize={{ mobileS: 13, tabletM: 16 }}
                    color={InspiColors.JetBlack500}
                  >
                    {author?.name}
                  </Text>
                  <Text
                    type="p"
                    fontWeight={400}
                    fontSize={{ mobileS: 11, tabletM: 14 }}
                    color={InspiColors.JetBlack200}
                  >
                    {author?.role && author?.role}
                  </Text>
                </SXTitle>
              </Box>
            </SXHeaderProfile>
            <SXBoxDate>
              <Text
                type="p"
                fontSize={"11px"}
                color={InspiColors.JetBlack200}
                textAlign="right"
              >
                {moment(c_at).utcOffset(7).format("DD MMMM YYYY, HH:mm")}
              </Text>
            </SXBoxDate>
          </SXInformationHeader>

          <SXDescription>
            <Text
              type="p"
              fontWeight={400}
              fontSize={{ mobileS: 13, tabletM: 16 }}
              color={InspiColors.JetBlack500}
            >
              {comment}
            </Text>
          </SXDescription>
        </SXRightReply>
      </SXReplyContentInformation>
    </SXReplyContent>
  );
};

export default ReplyContent;
