import React from "react";
import styled from "styled-components";
import { Box, Text, InspiColors } from "@inspigoid/inspids-react";

const DetailForumContentResponsive = ({
  image,
  title,
  description,
  handleOnCloseModalRule,
}) => {
  return (
    <SXContainer>
      <SXHeader>
        <SXLogo>
          <img src={image} alt={title} />
        </SXLogo>
        <Box>
          <Text
            type="p"
            fontSize={"16px"}
            fontWeight={700}
            color={InspiColors.JetBlack500}
          >
            {title}
          </Text>
        </Box>
      </SXHeader>
      <SXDescription>
        <Text
          type="p"
          fontWeight={400}
          fontSize={"16px"}
          color={InspiColors.JetBlack500}
        >
          {description}
        </Text>
      </SXDescription>
      <SXRuleContainer>
        <SXAssetRuleContainer>
          <img src="/assets/images/Asset.png" alt="" />
        </SXAssetRuleContainer>
        <SXRuleContent>
          <Text
            type="p"
            fontSize={"11px"}
            fontWeight={700}
            color={InspiColors.JetBlack500}
            textAlign="left"
          >
            Please read these rules before posting
          </Text>
          <Text
            type="p"
            textAlign={"left"}
            fontWeight={700}
            fontSize={"11px"}
            color={InspiColors.InspirationBlue500}
            onClick={() => handleOnCloseModalRule()}
          >
            Check Rule
          </Text>
        </SXRuleContent>
      </SXRuleContainer>
    </SXContainer>
  );
};

export default DetailForumContentResponsive;

const SXContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
`;

const SXHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
`;

const SXLogo = styled.div`
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const SXDescription = styled.div``;

export const SXRuleContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background: #e6eef8;
  align-items: center;
  border-radius: 20px;
`;

export const SXAssetRuleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;

  img {
    width: 80px;
    height: 40px;
  }
`;

export const SXRuleContent = styled.div`
  display: flex;
  flex-direction: column;
`;
