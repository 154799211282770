// Libraries
import React from "react";
import ReactDom from "react-dom";
import { Dialog } from "@headlessui/react";

// Packages
import { Box, InspiColors, Text } from "@inspigoid/inspids-react";

// Components
import Overlay from "../Overlay/Overlay";

// Children
import CreatePostForm from "./CreatePostForm/CreatePostForm";

// Styled
import {
  SXDialogPanel,
  SXHeader,
  SXHeaderTitle,
  SXTitleIcon,
  SXTitleText,
  SXTitleClose,
  SXBody,
} from "./CreatePostModal.styled";

const CreatePostModal = ({
  isOpen,
  onClose,
  categoryId,
  forumId,
  setRefreshPost,
}) => {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);

  const modalContent = isOpen ? (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <SXDialogPanel>
          <SXHeader>
            <SXHeaderTitle>
              <SXTitleIcon>
                <img src="/assets/icons/forum-icon.svg" alt="" />
              </SXTitleIcon>

              <SXTitleText>
                <Text type="h6" fontSize={{ mobileS: 16, tabletM: 20 }}>
                  Kirim Pertanyaan
                </Text>
              </SXTitleText>

              <SXTitleClose onClick={() => onClose()}>
                <img
                  src="/assets/icons/close.svg"
                  alt=""
                  height={24}
                  width={24}
                />
              </SXTitleClose>
            </SXHeaderTitle>
            <Box>
              <Text
                type="p"
                color={InspiColors.JetBlack200}
                fontSize={{ mobileS: 13, tabletM: 16 }}
              >
                Tanyakan pertanyaan kamu ke dalam forum
              </Text>
            </Box>
          </SXHeader>

          <SXBody>
            <CreatePostForm
              categoryId={categoryId}
              forumId={forumId}
              setRefreshPost={setRefreshPost}
              onClose={onClose}
            />
          </SXBody>
        </SXDialogPanel>
      </Dialog.Panel>

      <Overlay />
    </Dialog>
  ) : null;

  if (mounted) {
    return ReactDom.createPortal(
      modalContent,
      document.getElementById("modal-portal")
    );
  } else {
    return null;
  }
};

export default React.memo(CreatePostModal);
