import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import { Dialog } from "@headlessui/react";
import Lottie from "lottie-react";

// Packages
import { Box, InspiColors, Text, Button } from "@inspigoid/inspids-react";

// Components
import Overlay from "../Overlay/Overlay";

// styled
import {
  SXDialogPanel,
  SXHeader,
  SXHeaderDecoration,
  SXHeaderLottie,
  SXBody,
  SXDescription,
  SXDescriptionContent,
} from "./RulesModal.styled";

// lottie
import rules from "./Lottie/rules.json";

/**
 * @data copyRules
 */
import copyRules from "./copy-rules.json"

const RulesModal = ({ isOpen, onClose = () => {} }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { title, rules: descriptionRule } = React.useMemo(() => {
    return copyRules;
  }, []);

  const modalContent = isOpen ? (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <SXDialogPanel>
          <SXHeader>
            <SXHeaderDecoration>
              <img src="/assets/modal/decorations/flower.svg" alt="flower" />
            </SXHeaderDecoration>

            <SXHeaderLottie>
              <Lottie animationData={rules} loop={true} />
            </SXHeaderLottie>
          </SXHeader>

          <SXBody>
            <Box mb={10} style={{ textAlign: "center" }}>
              <Text
                type="p"
                textAlign="center"
                fontWeight={700}
                fontSize={{ mobileS: 16, tabletM: 20 }}
              >
                {title}
              </Text>
            </Box>
            <SXDescription>
              {descriptionRule.map((item, index) => (
                <SXDescriptionContent key={index}>
                  <Box>
                    <img src="/assets/icons/bullet.svg" alt="bullet" />
                  </Box>
                  <Box>
                    <Text
                      type="p"
                      fontWeight={400}
                      fontSize={{ mobileS: 13, tabletM: 16 }}
                      color={InspiColors.JetBlack300}
                    >
                     {item}
                    </Text>
                  </Box>
                </SXDescriptionContent>
              ))}
            </SXDescription>

            <Box mt={40} display="flex" justifyContent="center">
              <Button width={250} buttonSize="large" onClick={() => onClose()}>
                Saya mengerti
              </Button>
            </Box>
          </SXBody>
        </SXDialogPanel>
      </Dialog.Panel>
      <Overlay />
    </Dialog>
  ) : null;

  if (mounted) {
    return ReactDom.createPortal(
      modalContent,
      document.getElementById("modal-portal")
    );
  } else {
    return null;
  }
};

export default RulesModal;
