import React from "react";
import { Dialog } from "@headlessui/react";

// Styled
import { SXOverlay } from "./Overlay.styled";
const Overlay = () => {
  return (
    <Dialog.Overlay>
      <SXOverlay />
    </Dialog.Overlay>
  );
};

export default Overlay;
