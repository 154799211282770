import React, { useEffect, useState } from "react";
import { Box, Text, InspiColors } from "@inspigoid/inspids-react";
import { useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
// Styled
import {
  SXHeader,
  SXHeaderInformation,
  SXHomeView,
  SXDivider,
  SXContent,
  SXSpinner,
} from "./HomeForumView.styled";

// ForumCategoryList
import ForumCategoryList from "./ForumThreadCategory/ForumCategoryList";

// request
import Request from "redux/request";

// api
import { content } from "utils/apiroutelist";

// components
import NotFound from "components/NotFound/NotFound";
import Forbidden from "components/Forbidden/Forbidden";

const HomeForumView = () => {
  const { path } = useParams();
  const [forum, setForum] = useState({
    loading: true,
    status: null,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    fetchForumByPath(path);
  }, [path]);

  const fetchForumByPath = async (path) => {
    try {
      const request = new Request(null, null, true);
      const response = await request.get(content.forumByPath(path));
      setForum({
        loading: false,
        status: response?.status,
      });
    } catch (error) {
      setForum({
        loading: false,
        status: error.response.status,
      });
    }
  };

  if (forum.loading) {
    return (
      <SXSpinner>
        <MoonLoader size={40} color={InspiColors.JetBlack400} />
      </SXSpinner>
    );
  }

  if (forum.status && forum.status === 404) {
    return <NotFound />;
  }

  if (forum.status && forum.status === 403) {
    return <Forbidden text={"Kamu tidak memliki akses ke forum ini"} />;
  }

  return (
    <SXHomeView>
      <SXHeader>
        <SXHeaderInformation>
          <Box mb={10}>
            <Text
              type="h5"
              fontSize={{
                tabletM: 24,
                mobileS: 20,
              }}
            >
              InspiForum
            </Text>
          </Box>

          <Box>
            <Text
              type="p"
              fontSize={{
                tabletM: 16,
                mobileS: 13,
              }}
              color={InspiColors.JetBlack200}
            >
              Tingkatkan kemampuan secara rutin melalui knowledge sharing
            </Text>
          </Box>

          <SXDivider />
        </SXHeaderInformation>
      </SXHeader>

      <SXContent>
        <ForumCategoryList />
      </SXContent>
    </SXHomeView>
  );
};

export default HomeForumView;
