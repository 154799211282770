// Libraries
import styled from "styled-components";

export const SXHomeView = styled.div``;

export const SXHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.2rem;
`;

export const SXHeaderInformation = styled.div`
  width: 100%;
`;

export const SXDivider = styled.div`
  margin-top: 20px;
  border-bottom: 2px solid rgb(234, 234, 233);
`;

export const SXContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const SXSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15rem;
`;
