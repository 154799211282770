import React, { useRef } from "react";

// Packages
import { Box, Text, InspiColors } from "@inspigoid/inspids-react";

// Styled
import { SXInputChat } from "./InputChat.styled";

const InputChat = ({
  placeholder,
  value,
  onChange,
  name,
  id,
  disabled = false,
  onSubmited,
  errorMessage,
}) => {
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef && inputRef.current && inputRef.current.focus();
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <SXInputChat
        onClick={handleClick}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            onSubmited && onSubmited();
          }
        }}
        disabled={disabled}
      >
        <input
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          id={id}
          disabled={disabled}
        />
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onSubmited}
        >
          <path
            d="M18.957 11.6687L14.2821 9.6234C12.0598 8.65118 10.9487 8.16507 10.3213 8.29758C9.47024 8.47733 8.83293 9.18657 8.74487 10.052C8.67996 10.6899 9.28167 11.7429 10.4851 13.8489C10.7582 14.3269 10.8948 14.5659 10.9618 14.816C11.0525 15.1549 11.0525 15.5118 10.9618 15.8507C10.8948 16.1008 10.7582 16.3398 10.4851 16.8178C9.28167 18.9237 8.67996 19.9767 8.74487 20.6147C8.83293 21.4801 9.47024 22.1893 10.3213 22.3691C10.9487 22.5016 12.0598 22.0155 14.2821 21.0433L18.957 18.998L18.957 18.998C22.548 17.4269 24.3435 16.6414 24.3435 15.3333C24.3435 14.0253 22.548 13.2398 18.957 11.6687Z"
            fill="currentcolor"
          />
        </svg>
      </SXInputChat>
      {errorMessage ? (
        <Text type="p" fontSize={"11px"} color={InspiColors.SizzlingRed500}>
          {errorMessage}
        </Text>
      ) : null}
    </Box>
  );
};

export default InputChat;
