import React from "react";

// PostContent
import PostContent from "components/PostContent/PostContent";

const PostContentList = ({ posts }) => {
  return (
    <React.Fragment>
      {posts.map((p, i) => {
        return <PostContent key={i} post={p} />;
      })}
    </React.Fragment>
  );
};

export default PostContentList;
