import styled from "styled-components";

// Packages
import { InspiColors } from "@inspigoid/inspids-react";

export const SXInputChat = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  background-color: ${({ disabled }) =>
    disabled ? InspiColors.JetBlack50 : "white"};
  border-radius: 4px;
  border: 1px solid ${InspiColors.JetBlack50};
  justify-content: space-between;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px;
    }
  }

  input {
    border: none;
    background-color: transparent;
    outline: none;
    flex-grow: 1;
  }

  svg {
    color: ${({ disabled }) =>
      disabled ? InspiColors.JetBlack300 : InspiColors.InspirationBlue500};
    cursor: pointer;
  }
`;
