// Libraries
import { combineReducers } from "@reduxjs/toolkit";

// reducers
import loginReducer from "./account/_login";
import forumCategoryReducer from "./content/_forum-categories";
import forumReducer from "./content/_forum";
import postReducer from "./content/_posts";

const reducers = combineReducers({
  user: loginReducer,
  forumCategories: forumCategoryReducer,
  forum: forumReducer,
  post: postReducer,
});

export default reducers;
