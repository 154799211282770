// Libraries
import React from "react";

// Type
export const NotificationType = {
  Success: "success",
  Warning: "warning",
  Error: "error",
};

const NotificationContext = React.createContext({
  show: false,
  type: NotificationType.Success,
  message: "",
  open: () => {},
});

export default NotificationContext;
