import { useEffect,  useState } from "react";

// Request
import Request from "redux/request";

// api
import {
  getReplyByPostId,
  createReplyByPostId,
} from "utils/apiroutelist";

const useReplies = (postId) => {
  const [replies, setReplies] = useState({
    loading: true,
    status: null,
    data: [],
  });
  const [reply, setReply] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    getReplies(postId);
  }, [postId]);

  const handleSubmitReply = async () => {
    if (reply === "") {
      return setError("cannot empty");
    }

    try {
      const request = new Request(null, null, true);
      const response = await request.post(createReplyByPostId(postId), {
        comment: reply,
      });
      if (response?.status === 201) {
        setReply("");
        getReplies(postId);
      }
      setError(null);
    } catch (error) {
      setError("sesuatu terjadi");
    }
  };

  const handleChangeInput = (e) => {
    setReply(e.target.value);
  };

  const getReplies = async (postId) => {
    try {
      const request = new Request(null, null, true);
      const response = await request.get(getReplyByPostId(postId));
      setReplies({
        loading: false,
        status: response?.status,
        data: response?.data?.data,
      });
    } catch (error) {
      setReplies({
        loading: false,
        status: error?.response?.status,
        data: [],
      });
    }
  };

  return {
    replies,
    handleChangeInput,
    handleSubmitReply,
    reply,
    errorAddReply: error,
  };
};

export default useReplies;
