import { apiActionType } from "../../types/apiType";
import { loginActionType } from "../../types/loginType";
import Request from "../../request";
import routes from "utils/routes";
import { account } from "utils/apiroutelist";
import { requiresAuth } from "@inspigoid/inspigo-auth-package";
import { ORIGIN } from "utils/constant";

export const apiStart = (payload) => {
  return {
    type: apiActionType.API_START,
    payload: payload,
  };
};
export const apiEnd = (payload) => {
  return {
    type: apiActionType.API_STOP,
    payload: payload,
  };
};

export const apiError = (payload) => {
  return {
    type: apiActionType.API_ERROR,
    payload: payload,
  };
};

export const getAccount = (response) => {
  return {
    type: loginActionType.GET_ACCOUNT,
    payload: {
      status: response.status,
      data: response.data,
    },
  };
};

export const fetchAccount = () => async (dispatch) => {
  dispatch(apiStart({ message: "Mendapatkan Akun" }));
  try {
    const response = await requiresAuth(ORIGIN, {
      withTokenParam: true,
    })
    dispatch(getAccount({
      status: 200,data : response}));
  } catch (error) {
    throw error;
  }
};
