import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";

import { BottomSheet } from "react-spring-bottom-sheet";

import "react-spring-bottom-sheet/dist/style.css";

const BottomSheetComponent = ({ open, onDismiss, children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const bottomSheetContent = open ? (
    <BottomSheet
      onDismiss={onDismiss}
      open={open}
      snapPoints={({ minHeight }) => minHeight}
      style={{
        zIndex: 9999,
      }}
    >
      {children}
    </BottomSheet>
  ) : null;

  if (mounted) {
    return ReactDom.createPortal(
      bottomSheetContent,
      document.getElementById("bottom-sheet")
    );
  } else {
    return null;
  }
};

export default BottomSheetComponent;
