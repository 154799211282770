import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";

export const SXHeader = styled.header`
  position: relative;
  background-color: white;
  width: 100%;
  height: 6.4rem;
  position: sticky;
  top: 0;
  left: 0;
  filter: drop-shadow(0px 4px 40px rgba(100, 100, 100, 0.1));
  z-index: 999;

  @media (max-width: ${breakpoints.mobileL}) {
    height: 4.4rem;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  }
`;

export const SXInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: inherit;
`;

export const SXLogo = styled.div`
  display: flex;
  align-items: center;
  width: 27.5rem;
  height: 2.4rem;
  gap: 2rem;
  @media (max-width: ${breakpoints.mobileL}) {
    gap: 1rem;
  }
`;

export const SXBackButton = styled.div`
  display: flex;
  width: 4rem;
  height: 4rem;
  cursor: pointer;

  @media (max-width: ${breakpoints.mobileL}) {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const SXLogoImage = styled.div`
  display: flex;
  width: 25.5rem;
  height: 2.4rem;
  height: auto;

  @media (max-width: ${breakpoints.mobileL}) {
    width: 17.5rem;
    height: 1.6rem;
  }
`;

export const SXUser = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-transform: capitalize;
`;

export const SXUserName = styled.div`
  @media (max-width: ${breakpoints.mobileL}) {
    display: none;
  }
`;

export const SXAvatar = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1/1;

  @media (max-width: ${breakpoints.mobileL}) {
    width: 3.2rem;
    height: 3.2rem;
  }
`;
