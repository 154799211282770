// Libraries
import styled from "styled-components";
import { css } from "styled-components";
import { breakpoints } from "utils/breakpoints";

// Packages
import { InspiColors } from "@inspigoid/inspids-react";

export const globalHorizontalPadding = css`
  padding: 0 7.5rem;
  @media (max-width: ${breakpoints.tabletL}) {
    padding: 0 8vw;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    padding: 0 20px;
  }
`;

export const SXFooter = styled.footer`
  ${globalHorizontalPadding};
  font-size: 16px;
  background-color: #e6e6e6;
  display: flex;
  justify-content: space-between;
  height: 40px;
  /* margin-top: 50px; */
  align-items: center;
  color: ${InspiColors.JetBlack200};
  .right {
    text-align: right;
  }

  @media (max-width: ${breakpoints.mobileL}) {
    /* margin-top: 30px; */
    font-size: 11px;
  }
  width: 100%;
`;
