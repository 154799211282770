import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// moment
import moment from "moment";

import { Avatar, Box, Text, InspiColors } from "@inspigoid/inspids-react";

// Request
import Request from "redux/request";

// Styled
import {
  SXPostContent,
  SXPostContentInformation,
  SXInformationHeader,
  SXHeaderProfile,
  SXTitle,
  SXInformationDetail,
  SXDescription,
  SXDescriptionDetail,
  SXAdditional,
  SXDate,
} from "./PostContent.styled";

// components
import Additional from "../Additional/Additional";

// api
import { likePost } from "utils/apiroutelist";

const defaultAvatarUrl =
  "https://cdn.inspigo.id/public/cover/default-profile/avatar5.png";

const PostContent = ({ post, isList = true, isDetailPage }) => {
  const history = useHistory();
  const [detailPost, setDetailPost] = useState(post);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const {
    id: postId,
    author,
    title,
    description,
    upvotes,
    c_at,
    repliesCount,
  } = detailPost;

  const handleOnClickPost = () => {
    const pathname = history.location.pathname;
    history.push(`${pathname}/post/${postId}`);
  };

  const handleOnClickLike = async () => {
    const request = new Request(null, null, true);
    const response = await request.put(likePost(postId));
    const post = response?.data?.data;
    setDetailPost((prevState) => {
      return {
        ...prevState,
        upvotes: post.upvotes,
      };
    });
  };

  return (
    <SXPostContent isList={isList}>
      <SXPostContentInformation
        isDetailPage={isDetailPage}
        isList={isList}
        onClick={() => {
          if (!isList) return;
          handleOnClickPost();
        }}
      >
        <SXInformationHeader>
          <SXHeaderProfile>
            <Avatar
              src={author?.cover ? author?.cover : defaultAvatarUrl}
              size={windowWidth <= 375 ? "S" : "M"}
              variant="circle"
            />
            <Box>
              <SXTitle>
                <Text
                  type="p"
                  fontWeight={700}
                  fontSize={{ mobileS: 13, tabletM: 16 }}
                >
                  {author?.name}
                </Text>
                <Text
                  type="p"
                  fontWeight={400}
                  fontSize={{ mobileS: 13, tabletM: 14 }}
                >
                  {author?.role && author?.role}
                </Text>
              </SXTitle>
            </Box>
          </SXHeaderProfile>
          <SXDate>
            <Text type="p" fontSize={"11px"} color={InspiColors.JetBlack200}>
              {moment(c_at).utcOffset(7).format("DD MMMM YYYY, HH:mm")}
            </Text>
          </SXDate>
        </SXInformationHeader>

        <SXInformationDetail>
          <Box mb={isDetailPage ? "10px" : "4px"}>
            <Text
              type="p"
              fontWeight={700}
              fontSize={
                isDetailPage
                  ? { mobileS: 16, tabletM: 20 }
                  : {
                      mobileS: 13,
                      tabletM: 16,
                    }
              }
              color={InspiColors.JetBlack500}
            >
              {title}
            </Text>
          </Box>
          {isDetailPage ? (
            <SXDescriptionDetail isDetailPage={isDetailPage}>
              <Text
                type="p"
                fontWeight={400}
                fontSize={{ mobileS: 13, tabletM: 16 }}
                color={InspiColors.JetBlack500}
              >
                {description}
              </Text>
            </SXDescriptionDetail>
          ) : (
            <SXDescription isDetailPage={isDetailPage}>
              <Text
                type="p"
                fontWeight={400}
                fontSize={{ mobileS: 13, tabletM: 16 }}
                color={InspiColors.JetBlack500}
              >
                {description}
              </Text>
            </SXDescription>
          )}
        </SXInformationDetail>
      </SXPostContentInformation>
      <SXAdditional>
        <Additional
          likes={`${upvotes.length} Disukai`}
          upvotes={upvotes}
          comments={`${repliesCount} Komentar`}
          onClickLike={handleOnClickLike}
          onClickPost={handleOnClickPost}
          isList={isList}
        />
      </SXAdditional>
    </SXPostContent>
  );
};

export default PostContent;
