import React from "react";

// Styled
import { SXFooter } from "./Footer.styled";

const Footer = () => {
  return (
    <SXFooter>
      <div>PT Inspigo Inovasi Indonesia</div>
      <div>Untuk bantuan support@inspigo.id</div>
    </SXFooter>
  );
};

export default Footer;
