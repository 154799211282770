import React, { useEffect } from "react";
import { MoonLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Packeges
import { InspiColors } from "@inspigoid/inspids-react";

// ForumCategory
import ForumCategory from "./ForumCategory";

// Styled
import {
  SXForumCategoryListContainer,
  SXSpinner,
} from "./ForumCategoryList.styled";

// actions
import {
  fetchForumCategories,
  clearForumCategory,
} from "redux/actions/content/_forum-categories";

// Components
import EmptyContent from "components/EmptyContent/EmptyContent";

// default tag image
const defaultTagImage = "/assets/images/default-tags-image.svg";

const ForumCategoryList = () => {
  const { forumCategories, user } = useSelector(
    ({ rootReducer }) => rootReducer
  );
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const data = forumCategories?.list;
  const isLoading = forumCategories?.loading;
  const status = forumCategories?.status;

  useEffect(() => {
    dispatch(fetchForumCategories());
  }, [user]);

  if (isLoading) {
    return (
      <SXSpinner>
        <MoonLoader size={40} color={InspiColors.JetBlack400} />
      </SXSpinner>
    );
  }

  if (status === 200 && data.length === 0) {
    return <EmptyContent text={"No data available"} />;
  }

  const handleOnClick = (id) => {
    history.push(`/forum/${params.path}/detail/${id}`);
    dispatch(clearForumCategory());
  };

  return (
    <SXForumCategoryListContainer>
      {data.map((f) => {
        return (
          <ForumCategory
            onClick={handleOnClick}
            key={f.id}
            id={f.id}
            forumCategoryName={f.name}
            forumCategoryImg={f.asset.content || defaultTagImage}
          />
        );
      })}
    </SXForumCategoryListContainer>
  );
};

export default ForumCategoryList;
