import styled from "styled-components";

// breakpoints
import { breakpoints } from "utils/breakpoints";

export const SXReplyContent = styled.div`
  display: flex;
  background-color: white;

  @media (max-width: ${breakpoints.mobileL}) {
    flex-direction: column;
    gap: 1rem;
  }

  & > div {
    @media (max-width: ${breakpoints.mobileL}) {
      padding: unset;
    }
  }
`;

export const SXReplyContentInformation = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-grow: 1;

  @media (max-width: ${breakpoints.mobileL}) {
    margin-bottom: 1rem;
  }
`;

export const SXAvatar = styled.div``;

export const SXRightReply = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SXInformationHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

export const SXHeaderProfile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const SXTitle = styled.div`
  display: flex;
  flex-direction: column;
  p {
    text-transform: capitalize;
  }
`;

export const SXDescription = styled.div`
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const SXBoxDate = styled.div`
  text-align: right;
`;
