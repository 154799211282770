// Libraries
import styled from "styled-components";

// Packages
import { InspiColors } from "@inspigoid/inspids-react";

// breakpoints
import { breakpoints } from "utils/breakpoints";

export const SXPostContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: ${({ isList }) =>
    isList ? `1px solid ${InspiColors.JetBlack50}` : "none"};
  border-radius: 8px;
  padding: ${({ isList }) => (isList ? "2rem" : 0)};

  @media (max-width: ${breakpoints.mobileL}) {
    flex-direction: column;
    padding: ${({ isList }) => (isList ? "2rem" : 0)};
    /* gap: 1rem; */
  }

  & > div {
    @media (max-width: ${breakpoints.mobileL}) {
      padding: unset;
    }
  }
`;

export const SXPostContentInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ isDetailPage }) => (isDetailPage ? "2rem" : "1rem")};
  flex-grow: 1;
  cursor: ${({ isList }) => (isList ? "pointer" : "default")};

  @media (max-width: ${breakpoints.mobileL}) {
    margin-bottom: 1rem;
  }
`;

export const SXInformationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.mobileL}) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const SXHeaderProfile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const SXTitle = styled.div`
  display: flex;
  flex-direction: column;
  p {
    text-transform: capitalize;
  }
`;

export const SXInformationDetail = styled.div`
  display: block;
`;

const clamp = 3;

export const SXDescription = styled.div`
  margin-bottom: 10px;
  margin-bottom: ${({ isDetailPage }) => (isDetailPage ? "20px" : "10px")};
  height: auto;

  // safari
  max-height: calc(24px * ${clamp});

  @media (max-width: ${breakpoints.mobileL}) {
    max-height: calc(19.5px * ${clamp});
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: ${clamp};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const SXDescriptionDetail = styled.div`
  margin-bottom: 10px;
  margin-bottom: ${({ isDetailPage }) => (isDetailPage ? "20px" : "10px")};
  height: auto;

  p {
    display: -webkit-box;
    -webkit-line-clamp: 0;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const SXAdditional = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const SXDate = styled.div`
  @media (max-width: ${breakpoints.mobileL}) {
    margin-bottom: 10px;
  }
`;
