export const COLOR = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DISABLED: "disabled",
  LINE: "line",
  BORDER: "border",
  HOVER: "hover",
  INPUT: "input",
  ERROR: "error",
  BLACK: "black",
  BLACK_2: "black-2",
  GRAY: "gray",
  GRAY_2: "gray-2",
  GRAY_3: "gray-3",
  SUCCESS: "success",
  INSP_BLUE: "insp-blue",
};

export const theme = {
  colors: {
    [COLOR.PRIMARY]: "#0055b8",
    [COLOR.SECONDARY]: "#ff7d6d",
    [COLOR.DISABLED]: "#dcdcdc",
    [COLOR.LINE]: "#d8d8d8",
    [COLOR.BORDER]: "#d9d9d9",
    [COLOR.HOVER]: "#ebebeb",
    [COLOR.INPUT]: "#dcdcdc",
    [COLOR.ERROR]: "#f85640",
    [COLOR.BLACK]: "#2d2a26",
    [COLOR.BLACK_2]: "#485565",
    [COLOR.GRAY]: "#909090",
    [COLOR.GRAY_2]: "#67748e",
    [COLOR.GRAY_3]: "#dcdcdc",
    [COLOR.SUCCESS]: "#6BCBB8",
    [COLOR.INSP_BLUE]: "#E6EEF8",
  },
  variables: {
    "mobile-breakpoint": "630px",
  },
};
