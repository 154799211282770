// Libraries
import styled from "styled-components";

export const SXSection = styled.section`
  max-width: 120rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0 2rem;
  }
`;
