import React from "react";

// Packages
import { Box } from "@inspigoid/inspids-react";

// Components
import InputChat from "../InputChat/InputChat";

const ReplyBox = ({
  show,
  disabled = false,
  handleChangeInput,
  handleSubmitReply,
  reply,
  errorAddReply,
}) => {
  return show ? (
    <Box display={"flex"} flexDirection={"column"}>
      <InputChat
        onChange={handleChangeInput}
        onSubmited={handleSubmitReply}
        value={reply}
        errorMessage={errorAddReply}
        disabled={disabled}
        placeholder="Tulis komentar"
      />
    </Box>
  ) : null;
};

export default ReplyBox;
