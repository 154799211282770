// Libraries
import styled from "styled-components";

// Utils
import { COLOR } from "utils/theme";

export const Wrapper = styled.div`
  max-width: ${({ wFull }) => (wFull ? "100%" : "660px")};
  height: auto;
  padding-bottom: ${({ inputOnly }) => (inputOnly ? "none" : "20px")};
  position: relative;
`;

export const LabelWrapper = styled.p`
  display: inline-block;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;

  span.label-desc {
    font-weight: 400;
  }

  &::after {
    content: ${({ required }) => (required ? '"*"' : "")};
    margin-left: 2px;
    color: ${({ theme }) => theme.colors[COLOR.ERROR]};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors[COLOR.ERROR] : theme.colors[COLOR.INPUT]};
  background-color: ${({ disabled }) =>
    disabled ? "hsl(0, 0%, 95%)" : "white"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "text")};
  border-radius: 4px;
  position: relative;

  p {
    color: ${({ disabled }) => (disabled ? "hsl(0, 0%, 60%)" : "inherit")};
  }

  svg {
    margin-right: 10px;
    height: 20px;
    color: ${({ theme }) => theme.colors[COLOR.INPUT]};
  }

  input {
    width: 100%;
    outline: none;
    border: none;

    &::placeholder {
      color: ${({ theme }) => theme.colors[COLOR.INPUT]};
    }
  }
`;

export const ErrorWrapper = styled.div`
  font-size: 12px;
  margin-top: 6px;

  label {
    color: ${({ theme }) => theme.colors[COLOR.ERROR]};
  }
`;

export const TextareaWrapper = styled.textarea`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors[COLOR.ERROR] : theme.colors[COLOR.INPUT]};
  border-radius: 4px;
  position: relative;
  outline: none;
  resize: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  &::placeholder {
    color: ${({ theme }) => theme.colors[COLOR.INPUT]};
  }
`;
