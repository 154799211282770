import { configureStore } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import thunk from "redux-thunk";

// root reducer
import rootReducer from "./reducers/rootReducer";

// config
import { env } from "../config";

// debug
const DEBUG = env.ENVIRONMENT === "development" || env.ENVIRONMENT === "local";

export const store = configureStore({
  devTools: DEBUG ? true : false,
  reducer: {
    rootReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([thunk]),
});

export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();
