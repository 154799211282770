import React from "react";

// Styled
import {
  Wrapper,
  LabelWrapper,
  InputWrapper,
  ErrorWrapper,
  TextareaWrapper,
} from "./Input.styled";

export const Input = (props) => {
  const { required, disabled, ...rest } = props;
  const error = props?.error;
  const inputOnly = props?.inputOnly;
  return (
    <Wrapper inputOnly={inputOnly} wFull>
      {!inputOnly && (
        <LabelWrapper required={required}>{props.label}</LabelWrapper>
      )}

      <InputWrapper error={error} disabled={disabled}>
        {disabled ? <p>{rest.value}</p> : <input type="text" {...rest} />}
      </InputWrapper>

      {error && (
        <ErrorWrapper>
          <label>{error}</label>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

Input.Textarea = (props) => {
  const { required, ...rest } = props;
  const error = props?.error;
  const inputOnly = props?.inputOnly;

  return (
    <Wrapper inputOnly={inputOnly} wFull>
      {!inputOnly && (
        <LabelWrapper required={required}>{props.label}</LabelWrapper>
      )}

      <TextareaWrapper rows="8" {...rest} />

      {error && (
        <ErrorWrapper>
          <label>{error}</label>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};
